import { Modal, Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CertificationTextField from './certificationComponents/certificationTextField';
import CertificationDimensionGrid from './certificationComponents/certificationDimensionGrid';
import Ntep_Icon from './../../../../Assets/Ntep_Icon.png';
import Canada_Icon from './../../../../Assets/Canada_Icon.png';
import Info_Icon from './../../../../Assets/Info_Icon.png';

export default function Certification(props: any) {
    const { isOpen, setIsOpen, machine, settings } = props
    const [certificationResponse, setCertificationResponse] = useState<any>({});
    const [certificationLoaded, setCertificationLoaded] = useState<boolean>(false);
    const [deviceID, setDeviceID] = useState<string>("");
    const [ipAddress, setipAddress] = useState<string>("");
    const [certifier, setCertifier] = useState<string>("");
    const [certifierCode, setCertifierCode] = useState<string>("");
    const [scanMode, setScanMode] = useState<string>("");
    const [version, setVersion] = useState<string>("");
    const [minAndMaxTemps, setMinAndMaxTemps] = useState<number[]>([0, 0]);
    const [calibrationDate, setCalibrationDate] = useState<any>("");
    const [softwareVersion, setSoftwareVersion] = useState<string>("");
    const [model, setModel] = useState<string>("");
    const [yearOfManufacture, setYearOfManufacture] = useState<string>("");
    const [certificationIcon, setCertificationIcon ] = useState<any>();
    const scanModes = ["UNKNOWN", "CARGO", "PARCEL", "BLACK PLASTIC", "PRECISE", "OVERSIZED"];
    
    const styles: any = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                md: "45%",
                xs: "100%"
            },
            height: "85%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            borderRadius: '16px'
        },
        closeButton: {
            fontWeight: 700,
            float: 'right'
        },
        submitButton: {
            color: "#FFFFFF",
            backgroundColor: '#F48430',
            fontWeight: 700,
            '&:hover': {
                backgroundColor: '#F48430',
                border: 1,
                borderColor: '#262D6D'
            },
            '&:disabled': {
                backgroundColor: '#ffcaa1',
                border: 1,
                color: "#FFFFFF"
            },
            float: 'right'
        },
        certificationText: {
            fontSize: 28,
            fontWeight: 500,
            color: '#262D6D',
        },
        certificationIcon: {
            ntep: Ntep_Icon,
            canada: Canada_Icon
        }
    }

    useEffect(() => {
        if (isOpen) {
            setCertificationLoaded(false);
            retrieveCertification('GetCertification')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        if (certificationLoaded) {
            console.log(machine, 'SCAN MODE ')
            setDeviceID(machine.macAddress)
            setipAddress(machine.localIP.split(":")[0])
            setScanMode(scanModes[machine.scanMode])
            setCertifier(certificationResponse['Certifier'])
            setCertifierCode(certificationResponse['Code'])
            setMinAndMaxTemps(certificationResponse['TemperatureBounds'])
            setVersion(settings.Device.Version.value)
            setSoftwareVersion(certificationResponse['Version'])
            calibrationDateCalculator();
            if (machine.scanMode === 2) {
                setModel("CSPCL1")
            } else {
                setModel("CSPLT1")
            }
            setYearOfManufacture("2024")

            if (certificationResponse['Certifier'].includes('NTEP')) {
                setCertificationIcon(Ntep_Icon)
            } else if (certificationResponse['Certifier'].includes('Canada')) {
                setCertificationIcon(Canada_Icon)
            } else {
                // *** PLACE HOLDER INFO ICON UNTIL APPROPRIATE UNCERTIFIED ICON PROVIDED
                setCertificationIcon(Info_Icon)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificationLoaded])

    const toggleCertification = function () { setIsOpen(!isOpen) }

    const calibrationDateCalculator = function () {
        let calibrationSettings = settings.Calibration.Path.value;

        if (!calibrationSettings) {
            return;
        } else {
            let timeStamp = calibrationSettings.split('/')
            timeStamp = timeStamp.splice(-1)
            let splitTwo = timeStamp[0].split('_')
            let dateArray = [splitTwo[2]]; //year
            dateArray[1] = new Date(splitTwo[0].split('-')[0] + " 1, 2012").toLocaleDateString('en', { month: '2-digit' }) //month
            dateArray[2] = splitTwo[0].split('-')[1] //day
            let dateString = dateArray.join('-')
            let time = splitTwo[1].replaceAll("-", ":");
            setCalibrationDate(dateString + " " + time);
        }
    }

    async function retrieveCertification(cmd: string) {
        return await machine.connection.post(commands[cmd]())
            .then((response: any) => {
                console.log(response)
                if (response['Responses']['GetCertification']['code'] === '0') {
                    setCertificationLoaded(true)
                    setCertificationResponse(response['Responses']['GetCertification'])
                }
            })
            .catch((err: any) => {
                console.log(err)
                alert("Failed to retrieve Certification Settings");
                setIsOpen(false);
            })
    }

    const commands: any = {
        GetCertification: () => {
            return {
                "Requests": {
                    "GetCertification": {
                    }
                }
            }
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={toggleCertification}
        >
            <Grid container>
                <Box sx={styles.modal}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item md={6} xs={11}>
                            <Typography sx={styles.certificationText}>About</Typography>
                        </Grid>
                        <Grid item md={6} xs={1}>
                            <Button className="Blue-Button" sx={styles.closeButton} style={{ float: 'right' }} onClick={toggleCertification}>Close</Button>
                        </Grid>
                        {!certificationLoaded ?
                            (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <CircularProgress size={100} sx={{ color: "#F48430" }} />
                                </Grid>
                            )
                            :
                            (
                                <Grid item md={12}>
                                    <CertificationTextField label={"Version"} labelValue={version}></CertificationTextField>
                                    <CertificationTextField label={"Device ID"} labelValue={deviceID}></CertificationTextField>
                                    <CertificationTextField label={"Internal IP Address"} labelValue={ipAddress} ></CertificationTextField>
                                    <CertificationTextField label={"Last Calibration"} labelValue={calibrationDate}></CertificationTextField>

                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: 24, color: '#636383', pt: 1, textAlign: 'center' }}>Certification</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#636383', float: 'left' }}>Dimensions</Typography>
                                    </Grid>
                                    <CertificationDimensionGrid certificationResponse={certificationResponse} ></CertificationDimensionGrid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#636383', pt: 1, float: 'left' }}>Certification Information</Typography>
                                    </Grid>
                                    <CertificationTextField label={"Certificate of Conformity"} labelValue={`${certifier} - ${certifierCode} - ${scanMode}`} addIcon={true} icon_source={certificationIcon}></CertificationTextField>
                                    <CertificationTextField label={"Software Version"} labelValue={softwareVersion}></CertificationTextField>

                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#636383', pt: 1, float: 'left' }}>Operating Temperature</Typography>
                                    </Grid>

                                    <CertificationTextField label={"Minimum"} labelValue={`${minAndMaxTemps[0]} °C (${(minAndMaxTemps[0] * 9 / 5) + 32} °F)`}></CertificationTextField>
                                    <CertificationTextField label={"Maximum"} labelValue={`${minAndMaxTemps[1]} °C (${(minAndMaxTemps[1] * 9 / 5) + 32} °F)`}></CertificationTextField>
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#636383', pt: 1, float: 'left' }}>Device</Typography>
                                    </Grid>

                                    <CertificationTextField label={"Make"} labelValue={"Cargo Spectre"}></CertificationTextField>
                                    <CertificationTextField label={"Model"} labelValue={model}></CertificationTextField>
                                    <CertificationTextField label={"Year of Manufacture"} labelValue={yearOfManufacture}></CertificationTextField>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Grid>
        </Modal>
    )
}