import { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Matchmaking from './pages/Matchmaking/Matchmaking';
import Login from './pages/Login/Login'
import Control from './pages/Control/Control';
import { ThemeProvider } from '@mui/material'
import { Navbar } from './components/Navbar';
import { CssBaseline } from "@mui/material";
import { getCookie, logIntoHost } from './ts/Common';
import { useCookies } from 'react-cookie';
import SpectreTheme from './SpectreTheme';
import { Machine } from './ts/Connections';
import { MachineEvent } from './ts/MachineEvent';

const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [selectedMachine, setSelectedMachine] = useState<any>(false);
  const [signInProgress, setSignInProgress] = useState<boolean>(false);
  const [cookies, , removeCookie] = useCookies(['user64']);
  const [cookieSpinner, setCookieSpinner] = useState<boolean>(false);
  const [user, setUser] = useState<string>("");
  const [showMachineSettings, setShowMachineSettings] = useState<boolean>(false);
  const [showCalibration, setShowCalibration] = useState<boolean>(false);
  const [showSupport, setShowSupport] = useState<boolean>(false);
  const [showCertification, setShowCertification] = useState<boolean>(false);
  const [onlineMachines, setOnlineMachines] = useState<Array<Machine>>([]);
  const [machineEvents, setMachineEvents] = useState<MachineEvent[]>([]);
  const [localMachines, setLocalMachines] = useState<Array<Machine>>([]);

  useEffect(() => {
    const credentials = getCookie("user64")
    if (credentials) {
      setSignInProgress(true);
      setCookieSpinner(true);
      logIntoHost(credentials, `${MY_HOST}/api/Account/LoginPWA`)
        .then((response: any) => {
          if (response.ok) {
            setIsLoggedIn(true);
            setSignInProgress(false);
            setCookieSpinner(false);
            setUser(atob(credentials).split(":")[0])
          } else {
            console.log('cookie check failed, re enter password and login again')
            setSignInProgress(false);
            setCookieSpinner(false);
          }
        })
        .catch((err: any) => {
          console.log('Error logging in')
          setSignInProgress(false);
          setCookieSpinner(false);
        })
    }
  }, [])

  useEffect(() => {
    selectedMachine ? document.title = `Spectre Link - ${selectedMachine.machineName}` : document.title = 'Spectre Link'
  }, [selectedMachine]);

  if (!isLoggedIn) {
    return (
      <div>
        <ThemeProvider theme={SpectreTheme}>
          <Login setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} cookieSpinner={cookieSpinner} setUser={setUser} removeCookie={removeCookie}></Login>
        </ThemeProvider>
      </div>)
  } else {
    return (
      <ThemeProvider theme={SpectreTheme}>
        <CssBaseline />
        <Navbar setIsLoggedIn={setIsLoggedIn} user={user} removeCookie={removeCookie} setShowMachineSettings={setShowMachineSettings} setShowCalibration={setShowCalibration}
          setShowSupport={setShowSupport} setShowCertification={setShowCertification} selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine} onlineMachines={onlineMachines} setMachineEvents={setMachineEvents}/>
        <div className="App">
          <Routes>
            <Route path="login" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login />} />
            <Route path="/" element={isLoggedIn ? <Navigate to="/matchmaking" /> : <Login />} />
            <Route path='/matchmaking/*' element={< Matchmaking setIsLoggedIn={setIsLoggedIn} setSelectedMachine={setSelectedMachine} cookies={cookies} removeCookie={removeCookie} onlineMachines={onlineMachines} setOnlineMachines={setOnlineMachines} localMachines={localMachines} setLocalMachines={setLocalMachines}
              setMachineEvents={setMachineEvents}></Matchmaking>}></Route>
            <Route path='/matchmaking/control/:id' element={< Control machine={selectedMachine} showMachineSettings={showMachineSettings} setShowMachineSettings={setShowMachineSettings} showCalibration={showCalibration} setShowCalibration={setShowCalibration}
              showSupport={showSupport} setShowSupport={setShowSupport} showCertification={showCertification} setShowCertification={setShowCertification} onlineMachines={onlineMachines} setSelectedMachine={setSelectedMachine} machineEvents={machineEvents} setMachineEvents={setMachineEvents} />}></Route>
          </Routes>
        </div>
      </ThemeProvider>
    );

  }

}

export default App;