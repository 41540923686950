import "./RemoteControlPassive.css";
import { Box, TextField, Grid, Button } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import { Machine } from "../../../../ts/Connections";
import { useState, useEffect } from 'react';
import { Cargo } from "../../../../ts/Cargo";

interface RemoteControlPassiveProps {
    machine: Machine;
    cargoResponses: Cargo[];
}

export default function RemoteControlPassive(props: RemoteControlPassiveProps) {
    const { machine, cargoResponses } = props;
    const [barcodeEntered, setBarcodeEntered] = useState<boolean>(false);
    const [barcodeValue, setBarcodeValue] = useState<string>("");

    useEffect(() => {
        setBarcodeEntered(false);
    }, [cargoResponses])

    const styles = {
        barcodeComponent: {
            backgroundColor: '#FFFFFF',
            mt: 1
        },
        barcodeTextArea: {
            color: '#4B4E65',
            backgroundColor: '#EAEAF0',
        },
        barcodeAdornment: {
            fontSize: 20
        }
    }

    const onKeyDownHandler = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ): Promise<void> => {
        const { key } = event;
        if (key === 'Enter') {
            try {
                setBarcodeEntered(true);
                setBarcodeValue('');
                await machine.connection.post(commands['setBarcode']())
                    .then(() => {
                        setBarcodeValue('');
                    })
            } catch (error) {
                console.error('Failed to process barcode:', error);
                setBarcodeEntered(false);
            }
        }
    };

    const commands: {setBarcode: Function} = {
        setBarcode: () => {
            return {
                "Requests": {
                    "SetBarcode": {
                        "Value": barcodeValue
                    }
                }
            }
        }
    }

    return (
        <>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item md={11} xs={11} sx={styles.barcodeComponent}>
                        {barcodeEntered ? <LinearProgress sx={{
                            backgroundColor: '#262D6D',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: "#F48430",
                            }
                        }}></LinearProgress> : ''}
                        <TextField id="barcode-field" variant="outlined" onKeyDown={onKeyDownHandler} fullWidth sx={styles.barcodeTextArea} value={barcodeValue} onChange={(element) => { setBarcodeValue(element.target.value) }}
                            InputProps={{
                                startAdornment: <InputAdornment sx={styles.barcodeAdornment} position="start">Barcode | </InputAdornment>
                            }}
                        />
                        {barcodeEntered ? <LinearProgress sx={{
                            backgroundColor: '#262D6D',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: "#F48430"
                            }
                        }}></LinearProgress> : ''}
                    </Grid>
                </Grid>
            </Box>
            <Grid item container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                md={12}>
                <Grid item md={11} xs={11} pt={2}>
                    <Button className="Scan-Button passive" fullWidth disabled={true}>
                        Awaiting New Scans
                        <div className="circle"></div>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}