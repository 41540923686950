import { Modal, Grid, Box, Typography, Button, FormControl, TextField, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

export default function Support(props: any) {
    const { isOpen, setIsOpen, machine, settings } = props
    const [name, setName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [productCategory, setProductCategory] = useState<string[]>([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState<string>("");
    const [deviceLocation, setDeviceLocation] = useState<string[]>([]);
    const [selectedDeviceLocation, setSelectedDeviceLocation] = useState<string>("");
    const [problemDescription, setProblemDescription] = useState<string>("");
    const [supportOptionsLoaded, setSupportOptionsLoaded] = useState<boolean>(false);
    const [disableSupportButton, setDisableSupportButton] = useState<boolean>(true);
    const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"

    const toggleSupport = function () {
        return setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (name && selectedProductCategory && selectedDeviceLocation && problemDescription && phoneNumber && !emailError) {
            setDisableSupportButton(false)
        } else {
            setDisableSupportButton(true)
        }
    }, [name, selectedProductCategory, selectedDeviceLocation, problemDescription, emailError, isOpen, phoneNumber])

    useEffect(() => {
        if (isOpen === true) {
            retrieveSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const retrieveSettings = function () {
        return fetch(`${MY_HOST}/api/HubspotSupportOptions`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((data: any) => data.json())
            .then((data: any) => {
                return JSON.parse(data);
            })
            .then((data: any) => {
                setProductCategory(data['issue']['options'])
                setDeviceLocation(data['location']['options'])
                setSupportOptionsLoaded(true);
            })
            .catch(err => console.log('retrieve settings failed', err))
    }

    useEffect(() => {
        if (supportOptionsLoaded === true) {
            let savedLocation = settings.Support.Location.value;
            savedLocation ? setSelectedDeviceLocation(savedLocation) : savedLocation = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportOptionsLoaded])

    async function requestSupport(cmd: string) {
        setDisableSupportButton(true);
        settings.Support.Location.Set(selectedDeviceLocation)

        return await machine.connection.post(commands[cmd]())
            .then((response: any) => {
                // need to appropriately handle response to user 
                // right now we constantly receive a code=0 even if support doesn't open. 
                console.log(response)
            })
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value);

        // Email validation regex pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the entered email matches the email regex pattern
        if (value.length > 0) {
            setEmailError(!emailRegex.test(value));
        } else {
            setEmailError(false)
        }
    };

    const commands: any = {
        supportRequest: () => {
            return {
                "Requests": {
                    "RequestSupport": {
                        "Name": name,
                        "Email": email,
                        "Phone": phoneNumber,
                        "Problem": selectedProductCategory,
                        "Location": selectedDeviceLocation,
                        "Description": problemDescription
                    }
                }
            }
        }
    }

    const styles: any = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                md: "65%",
                sm: '75%',
                xs: "95%"
            },
            height: {
                md: '70%',
                xs: '80%'
            },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: {
                md: 4,
                xs: 2
            },
            overflow: 'auto',
            borderRadius: '16px'
        },
        closeButton: {
            fontWeight: 700,
            float: 'right'
        },
        submitButton: {
            fontWeight: 700,
            float: 'right'
        },
        dropDownSettingsBox: {
            backgroundColor: '#FFFFFF',
            textArea: {
                color: '#4B4E65'
            },
            mt: 1
        },
        textSettingBox: {
            backgroundColor: '#FFFFFF',
            textArea: {
                color: '#4B4E65',
            },
            mt: 1
        },
        text: {
            "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#808080",
            },
            input: {
                "&::placeholder": {
                    opacity: 1,
                },
            },
            pt: 1
        },
        supportText: {
            fontSize: 28,
            fontWeight: 500,
            color: '#262D6D',
        }
    }

    var selectProductOptions = productCategory.map((item: any, i: any) => {
        return <MenuItem key={i} value={item.value ?? ""}>{item['label']}</MenuItem>
    })

    var selectLocationOptions = deviceLocation.map((item: any, i: any) => {
        return <MenuItem key={i} value={item.value ?? ""}>{item['label']}</MenuItem>
    })

    return (
        <Modal
            open={isOpen}
            onClose={toggleSupport}
        >
            <Grid container>
                <Box sx={styles.modal}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item md={6} xs={11}>
                            <Typography sx={styles.supportText}>Support</Typography>
                        </Grid>
                        <Grid item md={6} xs={1}>
                            <Button className="Blue-Button" sx={styles.closeButton} style={{ float: 'right' }} onClick={toggleSupport}>Close</Button>
                        </Grid>
                        {!supportOptionsLoaded ?
                            (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <CircularProgress size={100} sx={{ color: "#F48430" }} />
                                </Grid>
                            )
                            :
                            (
                                <Grid item md={12} justifyContent='space-between'>
                                    <TextField variant="outlined" size="small" fullWidth value={name}
                                        sx={styles.text}
                                        onChange={(e: any) => { setName(e.target.value) }}
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={styles.textSettingBox.textArea} position="start">*Your Name: </InputAdornment>,
                                        }}
                                    />
                                    <TextField variant="outlined" size="small" fullWidth value={email}
                                        sx={styles.text}
                                        error={emailError}
                                        onChange={(e: any) => { handleEmailChange(e) }}
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={styles.textSettingBox.textArea} position="start">Contact Email Address: </InputAdornment>,
                                        }}
                                    />
                                    <TextField variant="outlined" size="small" fullWidth value={phoneNumber}
                                        sx={styles.text}
                                        onChange={(e: any) => { setPhoneNumber(e.target.value) }}
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={styles.textSettingBox.textArea} position="start">*Contact Phone Number: </InputAdornment>,
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            sx={styles.text}
                                            size="small"
                                            defaultValue=""
                                            value={selectedProductCategory}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment sx={styles.dropDownSettingsBox.textArea} position="start">*Product Category :</InputAdornment>
                                                )
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedProductCategory(e.target.value)
                                            }}
                                        >
                                            {selectProductOptions}
                                        </TextField>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            size="small"
                                            sx={styles.text}
                                            defaultValue=""
                                            value={selectedDeviceLocation}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment sx={styles.dropDownSettingsBox.textArea} position="start">*Device Location :</InputAdornment>
                                                )
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedDeviceLocation(e.target.value)
                                            }}
                                        >
                                            {selectLocationOptions}
                                        </TextField>
                                    </FormControl>
                                    <TextField variant="outlined" size="small" fullWidth multiline rows={4} placeholder='*Problem Description' value={problemDescription}
                                        sx={styles.text}
                                        onChange={(e: any) => { setProblemDescription(e.target.value) }}
                                    />
                                </Grid>
                            )}
                        <Grid item md={12} sx={{ pt: 2 }}>
                            <Button className="Orange-Button" sx={styles.submitButton} disabled={disableSupportButton} onClick={() => { requestSupport('supportRequest') }}>Open Support Ticket</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Modal>
    )
}