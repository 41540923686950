import { Settings } from './Settings';

function defaultErrorResponse() {
    return new Response(JSON.stringify({ error: "Fetch error" }), {
        status: 408,
        statusText: "Communication Timeout",
        headers: { "Content-Type": "application/json" },
    });
}

export async function fetch_with_timeout(url: string, resource: any, timeout: number = 10000) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);
    resource.signal = controller.signal;
    resource.headers["Accept"] = "application/json";
    try {
        return await fetch(url, resource);
    } catch (error) {
        console.log("An error occurred during fetch:", error);
        return defaultErrorResponse();
    }
}

export class IConnection {
    type(): string { return ""; }
    async file(name: string): Promise<string> { return ""; }
    async get_impl(cmd: string, timeout: number): Promise<Response> { throw Error(); }
    async get(cmd: string, timeout: number = 5000): Promise<string> {
        let result: Promise<string> = this.get_impl(cmd, timeout)
            .then(response => {
                return response.json();
            })
            .catch(() => {
                return defaultErrorResponse();
            })
        return result;
    }
    async post_impl(cmd: JSON, timeout: number): Promise<Response> { throw Error(); }
    async post(cmd: JSON, timeout: number = 50000): Promise<string> {
        let result: Promise<string> = this.post_impl(cmd, timeout)
            .then(response => {
                return response.json();
            })
            .catch(() => {
                return defaultErrorResponse();
            })
        return result;
    }

    async ping(machine: any): Promise<boolean> {
        try {
            return await this.get("ping", 5000)
                .then((response: any) => {
                    if (response && response['Responses']['Ping']['code'] === '0') {
                        return true;
                    } else {
                        return false;
                    }
                })
        }
        catch {
            return false;
        }
    }

    async testConnection(machine: any): Promise<boolean> {
        try {
            return await this.get("ping+version", 2500)
                .then((response: any) => {
                    if (response && response['Responses']['Ping']['code'] === '0') {
                        if (response['Responses']['Version']['code'] === '0') {
                            machine.version = response['Responses']['Version']['value']
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                })
        } catch (err) {
            return false;
        }
    }

    async calibrate(phase: string): Promise<string> {
        return this.get(`calibrate?Phase=${phase}`, 20000)
    }
}


export class RemoteConnection extends IConnection {
    ip: string
    protocol: string
    constructor(machine: any) {
        super();
        this.ip = this.convertIpAddressToSpectreCore(machine.publicIP)
        this.protocol = "https"
    }
    type(): string { return "Remote"; }
    // ***** NOTE - there is a double slash in front of file - for some reason images won't return from the machien at the moment
    async file(name: string): Promise<string> { return `${this.protocol}://${this.ip}/file/${name}` }

    async get_impl(cmd: string, timeout: number): Promise<Response> {
        let target_ip = `${this.protocol}://${this.ip}/${cmd}`
        console.log("FETCH: ", target_ip)

        return fetch_with_timeout(`${this.protocol}://${this.ip}/${cmd}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        }, timeout)
    }

    async post_impl(cmd: JSON, timeout: number): Promise<Response> {
        let target_ip = `${this.protocol}://${this.ip}`;
        console.log("POST FETCH: ", target_ip);
        return fetch_with_timeout(`${this.protocol}://${this.ip}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', // Set content type to JSON
            },
            body: JSON.stringify(
                cmd
            ),
        }, timeout)
    }
    convertIpAddressToSpectreCore(ipAddress: string) {
        // Remove the port number from the IP address
        const [address, port] = ipAddress.split(':');

        // Replace periods with hyphens and append the domain name
        const convertedAddress = address.replace(/\./g, '-') + '.spectrecore.com';

        // Combine the converted address with the port number
        const convertedIpAddress = convertedAddress + ':' + port;

        return convertedIpAddress;
    }
}

export class ProxyConnection extends IConnection {
    ip: string
    cookies: any
    constructor(machine: any, cookies: any) {
        super();
        this.ip = `https://proxy.spectre-licensing.com/api/${machine.machineName.replace(/ /g, '%20')}`;
        this.cookies = cookies
    }
    type(): string { return "Proxy" }

    async file(name: string): Promise<string> {
        return this.get(`fileprep/${name}`, 5000)
            .then((data: string) => {
                return `https://proxy.spectre-licensing.com/api/fileresolve/${data}`
            })
    }

    async get_impl(cmd: string, timeout: number): Promise<Response> {
        let target_machine = `${this.ip}/${cmd}`
        console.log("PROXY FETCH: ", target_machine)

        return fetch_with_timeout(target_machine, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Basic ${this.cookies}`,
                'Content-Type': 'application/json'
            },
        }, timeout)
    }

    async post_impl(cmd: JSON, timeout: number = 50000): Promise<Response> {
        let target_machine = `${this.ip}`
        console.log("PROXY FETCH: ", target_machine)

        return fetch_with_timeout(target_machine, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Basic ${this.cookies}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                cmd
            ),
        }, timeout)
    }

    async testConnection(machine: any): Promise<boolean> {
        try {
            return await this.get("ping%20version", 2500)
                .then((response: any) => {
                    if (response && response['Responses']['Ping']['code'] === '0') {
                        if (response['Responses']['Version']['code'] === '0') {
                            machine.version = response['Responses']['Version']['value']
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                })
        } catch (err) {
            return false;
        }
    }
}

export class LocalHostConnection extends RemoteConnection {
    id: string
    constructor(machine: any) {
        super(machine);

        const port = machine.localIP.split(':')[1] ?? "";
        this.ip = "localhost:" + port;
        this.protocol = "http";
        this.id = machine['serialNumber'] ?? machine['macAddress']
    }
    type(): string { return "LocalHost"; }

    async testConnection(machine: any): Promise<boolean> {
        try {
            return await this.get("id+version", 2500)
                .then((response: any) => {
                    if (response['Responses']['ID']['value'] === this.id) {
                        if (response['Responses']['Version']['code'] === '0') {
                            machine.version = response['Responses']['Version']['value']
                            return true
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                })
        } catch (err) {
            return false;
        }
    }
}

export async function get_preferred_connection(machine: JSON, cookies: any): Promise<IConnection | null> {
    let test_connections: Array<IConnection> = [
        new LocalHostConnection(machine),
        new RemoteConnection(machine),
        new ProxyConnection(machine, cookies)
    ];
    let pending: Array<Promise<boolean>> = []

    for (let connection of test_connections) {
        pending.push(connection.testConnection(machine))
    }
    let out = Promise.all(pending)
        .then(bool_array => {
            for (let i = 0; i < bool_array.length; ++i) {
                if (bool_array[i]) return test_connections[i];
            }
            return null;
        })
    return out;
}

export class Machine {
    connection: IConnection;
    machineName: string;
    localIP: string;
    publicIP: string;
    settings: Settings;
    matchmakingID: number;
    location: string;
    macAddress: string;
    scanMode: number;
    certification: any;
    version: string;
    serialNumber: string;

    constructor(_connection: IConnection, _name: string, _localIP: string, _publicIP: string, _matchmakingID: number, _location: string, _macAddress: string, _scanMode: number, _certification: any, _version: string, _serialNumber: string) {
        this.connection = _connection;
        this.machineName = _name;
        this.localIP = _localIP;
        this.publicIP = _publicIP;
        this.settings = new Settings(this.connection)
        this.matchmakingID = _matchmakingID;
        this.location = _location;
        this.macAddress = _macAddress
        this.scanMode = _scanMode
        this.certification = _certification
        this.version = _version
        this.serialNumber = _serialNumber
        this.settings.sync()
        this.settings.json()
    }
}

export class OfflineMachine {
    machineName: string;
    localIP: string;
    publicIP: string;
    matchmakingID: number;
    location: string;
    macAddress: string;
    scanMode: number;
    version: string;
    serialNumber: string;

    constructor(_machineName: string, _localIP: string, _publicIP: string, _matchmakingID: number, _location: string, _macAddress: string, _scanMode: number, _version: string, _serialNumber: string) {
        this.machineName = _machineName;
        this.localIP = _localIP;
        this.publicIP = _publicIP;
        this.matchmakingID = _matchmakingID;
        this.location = _location;
        this.macAddress = _macAddress;
        this.scanMode = _scanMode;
        this.version = _version;
        this.serialNumber = _serialNumber;
    }
}