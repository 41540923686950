import { Grid, Box, Typography, CircularProgress } from '@mui/material'
import CS_Logo from '../../Assets/CS_Logo.png'
import Link_Logo from '../../Assets/Link_Logo.png'
import EmailAndPasswordForm from './loginComponents/EmailAndPasswordForm';
import Login_Splash from '../../Assets/Login_Splash.jpg'

const styles = {
    imageGrid: {
        overflow: 'hidden'
    },
    imageBox: {
        maxHeight: '100%',
        overflow: 'hidden'
    },
    cs_logo: {
        height: 40,
        float: 'top',

    },
    link_logo: {
        height: 70,
        float: 'top',

    },
    barcodeBox: {
        backgroundColor: '#FFFFFF',
    },
    textHeader: {
        fontWeight: 700,
        fontSize: 20,
        color: "#262D6D",
    },
}

export default function Login(props: any) {
    const { setIsLoggedIn, signInProgress, setSignInProgress, cookieSpinner, setUser, removeCookie } = props;

    if (cookieSpinner) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '40%',
                }}
            >
                <CircularProgress size={200} sx={{ color: "#F48430"}} />
            </Grid>
        )
    } else {
        return (
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    minWidth: "100%",
                    height: "100vh",
                }}

            >
                <Grid item md={5} sx={styles.imageGrid}>
                    <Box sx={{
                        minWidth: "100%",
                        height: "100vh",
                        overflow: 'hidden',
                        display: {
                            xs: "none",
                            sm: "none",
                            md: "block",
                            lg: "block"
                        },
                    }}
                        component="img"
                        style={styles.imageBox}
                        src={Login_Splash}
                    />
                </Grid>
                <Grid item container md={7} direction="column"
                        alignItems="center"
                        justifyContent="center">
                    <Grid item lg={7} md={7} xs={12} sx={{ mt: 5 }} justifyContent="center">
                        <Typography sx={styles.textHeader}> Welcome to </Typography>&nbsp;&nbsp;&nbsp;
                    </Grid>
                    <Grid item sx={{ mb: 5 }} lg={7} md={7} xs={12}>
                        <Box
                                component="img"
                                sx={styles.link_logo}
                                alt="Cargo Spectre logo"
                                src={Link_Logo}
                            />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <EmailAndPasswordForm setIsLoggedIn={setIsLoggedIn} signInProgress={signInProgress} setSignInProgress={setSignInProgress} setUser={setUser} removeCookie={removeCookie}></EmailAndPasswordForm>
                    </Grid>
                    <Grid item lg={7} md={7} xs={12} sx={{ mt: 5 }} >
                        <Box
                            component="img"
                            sx={styles.cs_logo}
                            alt="Cargo Spectre logo"
                            src={CS_Logo}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}