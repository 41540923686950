import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography, FormControlLabel, Checkbox, Link, ListItem } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import SignInButton from './SignInButton';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { logIntoHost } from '../../../ts/Common';

const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"
const DOMAIN = process.env.REACT_APP_DOMAIN || '.spectre-licensing.com'

const styles = {
    textComponent: {
        backgroundColor: '#FFFFFF',
        // mt: 1
    },
    emailTextArea: {
        color: '#4B4E65',
        backgroundColor: '#EAEAF0',
        emailAdornment: {
            color: "#636383",
        },
    },
    passwordTextArea: {
        color: '#4B4E65',
        backgroundColor: '#EAEAF0',
        mt: 2,
        passwordAdornment: {
            color: "#636383",
        },
    },
    forgotPassword: {
        fontWeight: 700,
        fontSize: 16,
        color: "#262D6D",
        pb: 5,
        pt: 1,
        float: 'left',
    },
    agreementCheckBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#636383'
        },
        mt: 1,
    },
    textAgreement: {
        color: "#262D6D",
        float: 'left',
        mt: 1.2,
        fontSize: 14,
        pl: {
            xs: 1,
            sm: 1,
            md: 0
        }
    },
    CircularProgress: {
        mr: 1,
        color: "#FFFFFF"
    },
    InvalidLogin: {
        fontWeight: 750,
        color: '#D43636',
        display: 'list-item'
    }
}

export default function EmailAndPasswordForm(props: any) {
    const { setIsLoggedIn, signInProgress, setSignInProgress, setUser, removeCookie } = props;
    const [agreementChecked, setAgreementChecked] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);
    const [enableSignIn, setEnableSignIn] = useState<boolean>(false);
    const [, setEncodedEmailAndPassword] = useState<string>("");
    const [showLoginError, setShowLoginError] = useState<string>("none")

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const setUserNameAndPasswordEncoded = async function () {
        removeCookie('user64', { path: '/', domain: DOMAIN })
        var encodedCredentials = btoa(`${email}:${password}`)
        setEncodedEmailAndPassword(encodedCredentials);
        setSignInProgress(true);

        logIntoHost(encodedCredentials, `${MY_HOST}/api/Account/LoginPWA`)
            .then((response: any) => {
                if (response.ok) {
                    setIsLoggedIn(true);
                    setSignInProgress(false);
                    setUser(email);
                } else {
                    setSignInProgress(false);
                    setShowLoginError('block');
                }
            })
            .catch((err: any) => {
                console.log(err);
                setSignInProgress(false);
                alert("Error logging in, please try again")
            })
    }

    useEffect(() => {
        if (agreementChecked && email && password) {
            setEnableSignIn(true)
        } else {
            setEnableSignIn(false)
        }
    }, [agreementChecked, email, password])

    return (
        <React.Fragment>
            <Grid item lg={7} md={7} xs={11} sx={styles.textComponent}>
                <TextField id="login_username" variant="outlined" fullWidth sx={styles.emailTextArea} value={email}
                    onChange={(element) => {
                        setEmail(element.target.value);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment sx={styles.emailTextArea.emailAdornment} position="start">
                            <Typography sx={styles.emailTextArea.emailAdornment}>Email |</Typography>
                        </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item lg={7} md={7} xs={11} sx={styles.textComponent}>
                <TextField id="login_password" variant="outlined" fullWidth sx={styles.passwordTextArea} type={showPassword ? "text" : "password"} value={password}
                    onChange={(element) => {
                        setPassword(element.target.value);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment sx={styles.passwordTextArea.passwordAdornment} position="start">
                            <Typography sx={styles.passwordTextArea.passwordAdornment}>Password |</Typography>
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        
                    }}
                />
            </Grid>
            <Grid item lg={7} md={7} xs={11}>
                <Link href="https://spectre-licensing.com/Account/ForgotPassword">
                    <Typography sx={styles.forgotPassword}>Forgot your password? </Typography>
                </Link>
            </Grid>
            <Grid item md={7} xs={11} >
                <Typography sx={styles.textAgreement}>
                    <FormControlLabel
                        style={{  width: "4%", color: '#4B4E65' }}
                        sx={{pr: 2.5}}
                        control={<Checkbox sx={styles.agreementCheckBox.textArea} />}
                        label=""
                        checked={agreementChecked}
                        onChange={(element: any) => { setAgreementChecked(element.target.checked) }}
                    />
                    I agree to the Cargo Spectre <Link href="https://spectre-licensing.com/Home/GeneralTermsConditions">Terms and Conditions</Link></Typography>
            </Grid>
            <SignInButton signInProgress={signInProgress} enableSignIn={enableSignIn} setUserNameAndPasswordEncoded={setUserNameAndPasswordEncoded}></SignInButton>
            <Grid item md={7} xs={11} sx={{ display: showLoginError }}>
                <ListItem sx={styles.InvalidLogin}>Incorrect Email/Password</ListItem>
            </Grid>
        </React.Fragment>

    )
}