import { Grid, Button, CircularProgress } from '@mui/material';

const styles = {
    CircularProgress: {
        mr: 1,
        color: "#FFFFFF"
    }
}

export default function SignInButton(props: any) {
    const { enableSignIn, setUserNameAndPasswordEncoded, signInProgress } = props;

    if (signInProgress) {
        return (
            <Grid item md={7} xs={11}>
                <Grid item md={12} xs={12} pt={2}>
                    <Button className="Scan-Button" disabled fullWidth onClick={setUserNameAndPasswordEncoded}><CircularProgress sx={styles.CircularProgress} size={25} />Signing In...</Button>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid item md={7} xs={11}>
                <Grid item md={12} xs={12} pt={2}>
                    <Button className="Scan-Button" disabled={!enableSignIn} fullWidth onClick={setUserNameAndPasswordEncoded}>Sign In</Button>
                </Grid>
            </Grid>
        )
    }

}