import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Grid, Box, Button } from '@mui/material';
import Barcode_Mini_Icon from '../../../../Assets/Barcode_Mini_Icon.png';
import { getShortUnitString } from '../../../../ts/Common';

export default function TextScanEvent(props: any) {
    const { scanFailure, handleListItemClick, selectedScanIndex, cargoResponse, cargoResponseIndex, openDetailsModal } = props

    const styles = {
        length: {
            fontWeight: 750,
            color: '#369350',
        },
        width: {
            fontWeight: 750,
            color: '#1627BA',
        },
        height: {
            fontWeight: 750,
            color: '#D43636',
        },
        barcode: {
            fontWeight: 450,
            color: '#636383',
            textAlign: 'center'
        },
        uploaded: {
            fontWeight: 450,
            color: '#636383',
            display: 'inline',
            float: 'right'
        },
        failedX: {
            fontWeight: 600,
            color: '#FF0000',
            display: 'inline'
        },
        successCheck: {
            fontWeight: 600,
            color: '#1627BA',
            display: 'inline'
        },
        nullBox: {
            fontWeight: 600,
            display: 'inline'
        },
        x: {
            fontWeight: 750,
            color: '#262D6D',
            display: 'inline'
        },
        netText: {
            fontWeight: 450,
            color: '#636383',
            float: 'right'
        },
        netWeightText: {
            fontWeight: 750,
            color: '#262D6D',
            float: 'right'
        },
        timeSinceText: {
            fontWeight: 550,
            color: '#262D6D',
            float: 'right'
        },
        barcodeImage: {
            ml: 1
        },
        scanFailureButton: {
            backgroundColor: '#F2BB2D',
        },
        scanFailureText: {
            fontWeight: 750,
            color: '#1A1B23'
        }
    }

    return (
        <ListItemButton
            selected={cargoResponseIndex === selectedScanIndex}
            onClick={(event) => {
                handleListItemClick(event, cargoResponseIndex)
                openDetailsModal()
            }}
            sx={{
                mt: -1,
                mb: 2,
            }}
        >
            <Grid container justifyContent={"space-between"} sx={{
            }}>
                {scanFailure ?
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item md={3} display="flex" justifyContent="center" alignItems="center" sx={{ mb: 4, mt: 4 }}>
                            <Button sx={styles.scanFailureButton} variant="contained" fullWidth>
                                <Typography component={'span'} sx={styles.scanFailureText}>&#9888; Scan Failure</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Grid item xs={1}>
                            <Typography component={'span'} noWrap sx={{ textAlign: 'center' }}>
                                {cargoResponse.barcode !== '' ?
                                    <Box
                                        component="img"
                                        sx={styles.barcodeImage}
                                        alt="Barcode:"
                                        src={Barcode_Mini_Icon}
                                    /> : <Box />
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography component={'span'} noWrap sx={styles.barcode}>
                                {cargoResponse.barcode.length <= 25 ? cargoResponse.barcode : (cargoResponse.barcode.substr(0, 25) + '...')}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography component={'span'} sx={styles.timeSinceText}>{cargoResponse.timeStamp.toLocaleTimeString()}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'center' }}>
                            <Typography component={'span'} sx={styles.length}>(L)</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography component={'span'} sx={styles.length}>{cargoResponse.dimensions.length} {getShortUnitString(cargoResponse.units.length)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component={'span'} sx={styles.netText}>Wgt:&nbsp;<Typography component={'span'} sx={styles.netWeightText}>{cargoResponse.dimensions.weight.display} {cargoResponse.units.weight}</Typography></Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'center' }}>
                            <Typography component={'span'} sx={styles.width}>(W)</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography component={'span'} sx={styles.width}>{cargoResponse.dimensions.width}  {getShortUnitString(cargoResponse.units.length)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component={'span'} sx={styles.uploaded}>Uploaded: {cargoResponse.uploaded == null ? <Typography sx={styles.nullBox}>&#9744;</Typography> : cargoResponse.uploaded ? <Typography sx={styles.successCheck}>&#10003;</Typography> : <Typography display={'inline'}>&#9747;</Typography>}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'center' }}>
                            <Typography component={'span'} sx={styles.height}>(H)</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography component={'span'} sx={styles.height}>{cargoResponse.dimensions.height}  {getShortUnitString(cargoResponse.units.length)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component={'span'} sx={styles.uploaded}>Exported: {cargoResponse.exported == null ? <Typography sx={styles.nullBox}>&#9744;</Typography> : cargoResponse.exported ? <Typography sx={styles.successCheck}>&#10003;</Typography> : <Typography display={'inline'}>&#9747;</Typography>}</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </ListItemButton>
    )
}