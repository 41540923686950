import CustomFields from "./inputComponents/CustomFields"
import BarcodeField from "./inputComponents/BarcodeField"
import RemoteControlButtons from "./inputComponents/RemoteControlButtons"
import { Grid } from "@mui/material"
import RemoteControlPassve from "./inputComponents/RemoteControlPassive"

const styles = {
    customFields: {
        overflow: "auto",
        maxHeight: {
            xs: '50vh',
            sm: '60vh',
            md: '75vh'
        }
    },
}

export default function Inputs(props: any) {
    const { customFields, customFieldDropDownValues, customFieldSubmitValues, setCustomFieldSubmitValues, setCustomFieldSubmitValuesEmpty, customFieldsLoaded, barcodeValue,
        setBarcodeValue, machineActive, settings, showMachineSettings, settingsLoaded, machineCommand, machineScan, scanButtonPressed, setScanButtonPressed, disableScanButton, setDisableScanButton, cargoResponses,
        setMachineCommand, checkCustomFieldsReady, dimensionerMode, machine } = props

    return (
        <>
            <Grid item sx={styles.customFields}>
                {
                    dimensionerMode === "Passive" ?
                    <></>
                    :
                    <CustomFields
                        customFields={customFields} customFieldDropDownValues={customFieldDropDownValues} checkCustomFieldsReady={checkCustomFieldsReady}
                        customFieldSubmitValues={customFieldSubmitValues} setCustomFieldSubmitValues={setCustomFieldSubmitValues}
                        setCustomFieldSubmitValuesEmpty={setCustomFieldSubmitValuesEmpty} customFieldsLoaded={customFieldsLoaded}
                        scanButtonPressed={scanButtonPressed} cargoResponses={cargoResponses} barcodeValue={barcodeValue}
                    ></CustomFields>
                }
            </Grid >
            <Grid item>
                <Grid pb={{ xs: 20, sm: 20, md: 5 }}>
                    {
                        dimensionerMode === "Passive" ?
                        <RemoteControlPassve machine={machine} cargoResponses={cargoResponses}></RemoteControlPassve>
                        :
                        <>
                            <BarcodeField barcodeValue={barcodeValue} setBarcodeValue={setBarcodeValue} machineActive={machineActive} settings={settings} showMachineSettings={showMachineSettings} settingsLoaded={settingsLoaded}
                                machineCommand={machineCommand} machineScan={machineScan} scanButtonPressed={scanButtonPressed} setScanButtonPressed={setScanButtonPressed}></BarcodeField>
                            <RemoteControlButtons machineScan={machineScan}
                                machineActive={machineActive} disableScanButton={disableScanButton} setDisableScanButton={setDisableScanButton} cargoResponses={cargoResponses} settings={settings} settingsLoaded={settingsLoaded}
                                showMachineSettings={showMachineSettings} machineCommand={machineCommand} setMachineCommand={setMachineCommand} setScanButtonPressed={setScanButtonPressed}>
                            </RemoteControlButtons>
                        </>
                    }
                </Grid>
            </Grid>
        </>
    )
}