import { Modal, Grid, Stack, Box, Typography, Button, Divider, Checkbox } from '@mui/material'
import { Cancel, Launch } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import Barcode_Mini_Icon from '../../../Assets/Barcode_Mini_Icon.png'
import ScanImageCarousel from './CenterControlComponents/scanPictureComponents/ScanImageCarousel';
import ErrorDetails from './CenterControlComponents/ErrorDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { getShortUnitString } from '../../../ts/Common';

export default function ScanDetails(props: any) {
    const { openScanDetails, setOpenScanDetails, cargoResponse, imagePaths, setShowAuditDims, selectedScanIndex } = props

    const styles = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                md: "80%",
                sm: "95%",
                xs: "95%"
            },
            maxHeight: "95%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 3,
            overflow: 'auto',
            borderRadius: '16px'
        },
        barcode: {
            fontWeight: 450,
            color: '#636383',
            display: 'inline',
        },
        barcodeImage: {
            display: "inline",
            pr: 1,
            pl: 0.5
        },
        timeSinceText: {
            fontWeight: 550,
            color: '#262D6D',
            align: 'center'
        },
        divider: {
            backgroundColor: '#262D6D',
            mt: 2,
            mb: 2
        },
        length: {
            fontWeight: 750,
            color: '#369350',
            display: 'inline'
        },
        width: {
            fontWeight: 750,
            color: '#1627BA',
            display: 'inline'
        },
        height: {
            fontWeight: 750,
            color: '#D43636',
            display: 'inline'
        },
        net: {
            fontWeight: 750,
            color: '#E07F3B',
            display: 'inline'
        },
        dimText: {
            fontWeight: 750,
            display: 'inline'
        },
        uploadExportButtons: {
            color: "#FFFFFF",
            backgroundColor: '#262D6D',
            '&:hover': {
                backgroundColor: '#191E48',
                borderColor: '#FFFFFF'
            },
            '&:disabled': {
                color: "#FFFFFF",
                backgroundColor: '#D3D3DE'
            }
        },
        successCheck: {
            fontWeight: 600,
            color: '#1627BA',
            display: 'inline'
        },
        nullBox: {
            fontWeight: 600,
            display: 'inline'
        },
        reportButton: {
            mr: 1,
            border: '1px solid #D3D3DE',
            '&: hover': {
                borderColor: '#000000'
            },
        }
    };

    // place holder for scan failures
    const scanFailure = false;

    return (
        <Modal
            open={openScanDetails}
            onClose={() => { setOpenScanDetails(false) }}
        >
            {cargoResponse ?
                <Box sx={styles.modal}>
                    <Stack justifyContent={"space-around"} spacing={1} divider={<Divider orientation='horizontal'></Divider>}>
                        <Grid container
                            alignItems="center"
                        >
                            <Grid item md={6} xs={6} style={{ textAlign: "left" }}>
                                <Typography component={'span'} sx={styles.timeSinceText}>{cargoResponse.timeStamp.toLocaleTimeString()}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} style={{ textAlign: "right" }}>
                                {selectedScanIndex === 0 && cargoResponse ?
                                    <Button sx={styles.reportButton} onClick={() => { setShowAuditDims(true) }}><FlagIcon /></Button>
                                    :
                                    ""
                                }
                                <Button className="Blue-Button" disabled={!cargoResponse.cargoID}
                                    href={`https://spectre-licensing.com/Cargo/Details/${cargoResponse.cargoID}`} target="_blank"
                                ><Launch /></Button>
                            </Grid>
                            <Grid item md={12} xs={12} style={{ textAlign: 'left' }} sx={{ pt: 1 }} >
                                <Typography component={'span'} noWrap sx={styles.barcode}>
                                    {scanFailure ? '' : cargoResponse.barcode !== '' ?
                                        <Box
                                            component="img"
                                            sx={styles.barcodeImage}
                                            alt="Barcode:"
                                            src={Barcode_Mini_Icon}
                                        /> : <Box />
                                    }
                                    {scanFailure ? '' : cargoResponse.barcode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorDetails cargoResponse={cargoResponse}></ErrorDetails>
                            </Grid>
                        </Grid>
                        <Grid container
                            style={{ textAlign: "center" }}
                        >
                            <Grid container sx={{ pb: 1 }} direction={"row"} justifyContent={'space-between'} >
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.length}>Length</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.width}>Width</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.height}>Height</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.length}  {getShortUnitString(cargoResponse.units.length)}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.width}  {getShortUnitString(cargoResponse.units.length)}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.height}  {getShortUnitString(cargoResponse.units.length)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"} justifyContent={'space-between'}>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.net}>Weight</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.net}>Density</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.net}>Volume</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.weight.display} {cargoResponse.units.weight}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.density} {getShortUnitString(cargoResponse.units.density)}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component={'span'} sx={styles.dimText}>{cargoResponse.dimensions.volume} {getShortUnitString(cargoResponse.units.volume)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <ScanImageCarousel imagePaths={imagePaths} height={'100%'} width={'100%'}></ScanImageCarousel>
                            </Grid>
                        </Grid>
                        {
                            (_.has(cargoResponse, 'customFields.CustomFields.Field')) ?
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {cargoResponse['customFields']['CustomFields']['Field'].map((field: any) => (
                                                        <TableCell align="center" key={field['Name']}>{field['Name']}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    {cargoResponse['customFields']['CustomFields']['Field'].map((field: any) => {
                                                        if (field['Value'] === true || field['Value'] === false) {
                                                            return <TableCell align="center" key={field['Name']}><Checkbox checked={field['Value']} /></TableCell>
                                                        }
                                                        return <TableCell align="center" key={field['Name']}>{field['Value']}</TableCell>
                                                    }
                                                    )}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                :
                                ''
                        }
                        <Grid container style={{ textAlign: "center" }} >
                            {/* <Grid item xs={6}>
                                <Typography>Not Uploaded</Typography>
                                <Button
                                    disabled={true}
                                    sx={styles.uploadExportButtons}>
                                    <Replay /></Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Not Exported</Typography>
                                <Button
                                    disabled={true}
                                    sx={styles.uploadExportButtons}><Replay /></Button>
                            </Grid> */}
                            <Grid item md={6} xs={6}>
                                <Typography component={'span'} sx={styles.barcode}>Uploaded: {cargoResponse.uploaded == null ? <Typography sx={styles.nullBox}>&#9744;</Typography> : cargoResponse.uploaded ? <Typography sx={styles.successCheck}>&#10003;</Typography> : <Typography display={'inline'}>&#9747;</Typography>}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Typography component={'span'} sx={styles.barcode}>Exported: {cargoResponse.exported == null ? <Typography sx={styles.nullBox}>&#9744;</Typography> : cargoResponse.exported ? <Typography sx={styles.successCheck}>&#10003;</Typography> : <Typography display={'inline'}>&#9747;</Typography>}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button className="Blue-Button" onClick={() => { setOpenScanDetails(false) }} startIcon={<Cancel />}><Typography sx={{ fontWeight: 550, fontSize: 15 }}>Close</Typography> </Button>
                        </Grid>
                    </Stack>
                </Box>
                :
                <Box sx={styles.modal}>
                    <Typography>No Cargo Selected</Typography>
                </Box>
            }
        </Modal>
    )
}