import { List } from '@mui/material'
import PictureScanEvent from './scanHistoryComponents/PictureScanEvent';
import React, { useMemo } from 'react'
import TextScanEvent from './scanHistoryComponents/TextScanEvent';
import ScanFailure from './scanHistoryComponents/ScanFailure';
import TextEvent from './scanHistoryComponents/TextEvent';
import { MachineEvent, TextEventProps } from '../../../ts/MachineEvent';
import { Machine } from '../../../ts/Connections';
import { Cargo } from '../../../ts/Cargo';

interface ScanHistoryMobileProps {
    selectedScanIndex: number;
    setSelectedScanIndex: (index: number) => void;
    cargoResponses: Cargo[];
    machineEvents: MachineEvent[];
    setOpenScanDetails: (open: boolean) => void;
    openDetailsModal: () => void;
    machine: Machine;
}

export default function ScanHistoryMobile(props: ScanHistoryMobileProps) {
    const { selectedScanIndex, setSelectedScanIndex, cargoResponses, machineEvents, setOpenScanDetails, openDetailsModal, machine } = props

    // Create a combined timeline of machine events and scan events (and sort by timestamp)
    const timelineEvents = useMemo(() => {
        return [...machineEvents, ...cargoResponses].sort((a, b) =>  b.timeStamp.getTime() - a.timeStamp.getTime());
    }, [machineEvents, cargoResponses]);

    const handleListItemClick = (
        _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedScanIndex(index);
    };

    return (
        <List>
            {timelineEvents.map((historyEvent, index) => {
                if (index === 0) {
                    if (historyEvent instanceof Cargo && historyEvent.dimensionCode === "0") {
                        return (
                            // ****NOTE WHEN PASSING DOWN SINGLE SCAN RESPONSE CHANGE FROM PLURAL TO SINGULAR
                            // cargoResponses --> cargoResponse
                            <TextScanEvent key={index} cargoResponse={historyEvent} scanFailure={false}
                                    cargoResponseIndex={index} handleListItemClick={handleListItemClick} selectedScanIndex={selectedScanIndex}
                                    openDetailsModal={openDetailsModal} />
                            
                        )
                    } else if (historyEvent instanceof Cargo && historyEvent.dimensionCode !== "0") {
                        return (
                            <TextScanEvent key={index} cargoResponse={historyEvent} scanFailure={true} handleListItemClick={handleListItemClick}
                                cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal}></TextScanEvent>
                        )
                    } else if (historyEvent instanceof TextEventProps) {
                        return (
                            <TextEvent {...historyEvent}></TextEvent>
                        )
                    } else {
                        return <div key={index}>Unknown Item</div>
                    }
                }
                else {
                    if (historyEvent instanceof Cargo && historyEvent.dimensionCode === "0") {
                        return (
                            // ****NOTE WHEN PASSING DOWN SINGLE SCAN RESPONSE CHANGE FROM PLURAL TO SINGULAR
                            // cargoResponses --> cargoResponse
                            <PictureScanEvent key={index} cargoResponse={historyEvent} scanFailure={false}
                                cargoResponseIndex={index} handleListItemClick={handleListItemClick} selectedScanIndex={selectedScanIndex}
                                setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine}></PictureScanEvent>
                        )
                    } else if (historyEvent instanceof Cargo && historyEvent.dimensionCode !== "0") {
                        return (
                            <React.Fragment key={index}>
                                <ScanFailure key={index} cargoResponse={historyEvent} handleListItemClick={handleListItemClick}
                                    cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} openDetailsModal={openDetailsModal}></ScanFailure>
                            </React.Fragment>
                        )
                    } else if (historyEvent instanceof TextEventProps) {
                        return (
                            <TextEvent {...historyEvent}></TextEvent>
                        )
                    } else {
                        return <div key={index}>Unknown Item</div>
                    }
                }
            })}
        </List>
    )
}