import { Typography, Box } from '@mui/material';
import { TextEventProps } from '../../../../ts/MachineEvent';

const iconBackgroundColors = {
    primary: '#00004B',
    secondary: '#F8B160',
    info: '#0288d1',
    warning: '#F1C21B',
    error: '#DF4848',
    success: '#F48430',
}

export default function TextEvent(props: TextEventProps) {
    const styles = {
        outer: { backgroundColor: '#E4E4E4', display: 'flex', borderRadius: '5px', margin: '4px', mt: -1, mb: 2 },
        icon: { 
            backgroundColor: iconBackgroundColors[props.category? props.category : 'primary'],
            color: 'white', 
            width: '40px',
            height: '40px',
            borderRadius: '5px'
        },
        subject: { fontWeight: 'bold', display: 'inline', marginRight: '4px' },
        message: { display: 'inline' }
    }

    return (
        <Box sx={styles.outer}>
            {props.icon ? <props.icon sx={ styles.icon } /> : undefined}
            <Box alignContent='center' sx={{ margin: '8px' }}>
                <Typography sx={styles.subject}>{props.subject}</Typography>
                <Typography sx={styles.message}>{props.message}</Typography>
            </Box>
        </Box>
    )
}