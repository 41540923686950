import { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import FilePaths from './CenterControlComponents/FilePaths';
import DimensionFields from './CenterControlComponents/DimensionFields';
import MachineSettings from '../components/machineButtonComponents/MachineSettings';
import Calibration from '../components/machineButtonComponents/Calibration';
import { Cargo } from '../../../ts/Cargo';
import Support from '../components/machineButtonComponents/Support';
import Certification from '../components/machineButtonComponents/Certification';
import ScanImageCarousel from './CenterControlComponents/scanPictureComponents/ScanImageCarousel';
import ScanDetails from './ScanDetails';
import CustomFieldsCenter from './CenterControlComponents/CustomFieldsCenter';
import BarcodeCenterControl from './CenterControlComponents/BarcodeCenterControl';
import ErrorDetails from './CenterControlComponents/ErrorDetails';
import AuditDims from '../components/machineButtonComponents/AuditDims';

export default function CenterControl(props: any) {
    const { showMachineSettings, setShowMachineSettings, machine, showCalibration, setShowCalibration, setDisableScanButton, setOpenErrorModal, setErrorModalText,
        cargoResponse, settings, setSettings, setSettingsLoaded, selectedScanIndex, showSupport, setShowSupport, showCertification, setShowCertification, openScanDetails,
        setOpenScanDetails } = props

    const [showAuditDims, setShowAuditDims] = useState<boolean>(false);
    const [imagePaths, setImagePaths] = useState<string[]>([]);
    const [rawImagePaths, setRawImagePaths] = useState<string[]>([]);
    const [cloudPaths, setCloudPaths] = useState<string[]>([]);
    const [rawCloudPaths, setRawCloudPaths] = useState<string[]>([]);
    const [xmlPaths, setXmlPaths] = useState<string[]>([]);
    const [rawXmlPaths, setRawXmlPaths] = useState<string[]>([]);

    const styles = {
        centerControl: {
            backgroundColor: '#E2E2E9',
            borderRight: '2px solid',
            borderColor: '#D3D3DE',
        }
    }

    useEffect(()=> {
        setShowAuditDims(false);
        setImagePaths([]);
        setRawImagePaths([]);
        setCloudPaths([]);
        setRawCloudPaths([]);
        setXmlPaths([]);
        setRawXmlPaths([]);
    }, [machine])

    useEffect(() => {
        if (cargoResponse) {
            parseResponse(cargoResponse)
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargoResponse])

    async function retrieveFilePaths(responseArray: string[]) {
        return await Promise.all(responseArray.map(response => {
            return machine.connection.file(response)
        }))
    }

    async function parseResponse(cargoResponse: Cargo) {
        // remove barcode from filePaths before retrieving Filepath
        let filteredImages = cargoResponse.fileList.images.filter((image: any) => !image.includes('barcode'));

        await retrieveFilePaths(filteredImages)
            .then(paths => {
                paths = [...paths]
                setImagePaths(paths)
                setRawImagePaths(filteredImages);
            })

        await retrieveFilePaths(cargoResponse.fileList.clouds)
            .then(paths => {
                paths = [...paths]
                setCloudPaths(paths)
                setRawCloudPaths(cargoResponse.fileList.clouds);
            })

        await retrieveFilePaths(cargoResponse.fileList.xmls)
            .then(paths => {
                paths = [...paths]
                setXmlPaths(paths)
                setRawXmlPaths(cargoResponse.fileList.xmls);
            })
    }

    return (
        <>
            <Grid container
                style={{
                    paddingTop: 67
                }}
                sx={{ height: '100%' }}
            >
                <Grid item md={12} xs={12} sx={styles.centerControl}>
                    <MachineSettings isOpen={showMachineSettings} setIsOpen={setShowMachineSettings} machine={machine} showMachineSettings={showMachineSettings} setOpenErrorModal={setOpenErrorModal}
                        setErrorModalText={setErrorModalText} settings={settings} setSettings={setSettings} setSettingsLoaded={setSettingsLoaded}
                    />
                    <Calibration isOpen={showCalibration} setIsOpen={setShowCalibration} machine={machine} setDisableScanButton={setDisableScanButton} />
                    <Support isOpen={showSupport} setIsOpen={setShowSupport} machine={machine} settings={settings}></Support>
                    <Certification isOpen={showCertification} setIsOpen={setShowCertification} machine={machine} settings={settings}></Certification>
                    <ScanDetails openScanDetails={openScanDetails} setOpenScanDetails={setOpenScanDetails} cargoResponse={cargoResponse} imagePaths={imagePaths} setShowAuditDims={setShowAuditDims} selectedScanIndex={selectedScanIndex}></ScanDetails>
                    <AuditDims isOpen={showAuditDims} setIsOpen={setShowAuditDims} cargoResponse={cargoResponse} machine={machine}></AuditDims>
                    <BarcodeCenterControl cargoResponse={cargoResponse} setShowAuditDims={setShowAuditDims} selectedScanIndex={selectedScanIndex}></BarcodeCenterControl>
                    <Grid item md={12} display={{ xs: 'none', md: 'block' }} >
                        <ScanImageCarousel imagePaths={imagePaths} height={'75%'} width={'92%'}></ScanImageCarousel>
                    </Grid>
                    <Grid item md={12} display={{ xs: 'none', md: 'block' }} sx={{ pb: 1, pt: 1 }}>
                        <DimensionFields cargoResponse={cargoResponse} selectedScanIndex={selectedScanIndex}></DimensionFields>
                    </Grid>
                    <Grid item md={12} display={{ xs: 'none', md: 'block' }}>
                        <ErrorDetails cargoResponse={cargoResponse}></ErrorDetails>
                    </Grid>
                    <Grid item md={12} display={{ xs: 'none', md: 'block' }}>
                        <CustomFieldsCenter cargoResponse={cargoResponse} setShowAuditDims={setShowAuditDims} selectedScanIndex={selectedScanIndex}></CustomFieldsCenter>
                    </Grid>
                    <Grid item md={12} display={{ xs: 'none', md: 'block' }} >
                        <FilePaths imagePaths={imagePaths} cloudPaths={cloudPaths} xmlPaths={xmlPaths} rawImagePaths={rawImagePaths} rawCloudPaths={rawCloudPaths} rawXmlPaths={rawXmlPaths}></FilePaths>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}