import { SvgIconComponent } from '@mui/icons-material';

export enum NotificationCategory { 
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SUCCESS = "success",    
    INFO = "info",
    WARNING = "warning",            
    ERROR = "error",
}

export class MachineEvent {
    timeStamp: Date = new Date(Date.now());
    id?: string;
}

export class TextEventProps extends MachineEvent {
    icon?: SvgIconComponent;
    subject?: string;
    message?: string;
    category?: NotificationCategory;
}
