import { Button, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

export default function ScanButton(props: any) {
    const { machineScan, scanButtonText, machineCommand, machineActive, disableScanButton, setScanButtonPressed } = props

    const styles = {
        display: {
            display: {
                xs: 'none',
                sm: 'block'
            }
        },
        CircularProgress: {
            mr: 1,
            color: "#FFFFFF"
        }
    }

    if (!machineActive) {
        return (
            <Grid sx={styles.display} item md={11} xs={11} pt={2}>
                <Button className="Scan-Button" disabled={disableScanButton} fullWidth onClick={() => { setScanButtonPressed(true) }}> {scanButtonText}</Button>
            </Grid>
        )
    } else {
        return (
            <Grid sx={styles.display} item md={11} xs={11} pt={2}>
                <Button className="Scan-Button" disabled fullWidth onClick={() => { machineScan(`${machineCommand}`) }}><CircularProgress sx={styles.CircularProgress} size={25} />Scanning...</Button>
            </Grid>
        )
    }
}